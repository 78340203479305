import { configureStore } from "@reduxjs/toolkit"
import photoSessionsReducer from '../features/photoSessions/photoSessionsSlice'
import singlePhotoSessionReducer from '../features/photoSessions/singlePhotoSessionSlice'
import photosReducer from "../features/photos/photosSlice"


const store = configureStore({
    reducer: {
        photoSessions: photoSessionsReducer,
        singlePhotoSession: singlePhotoSessionReducer,
        photos: photosReducer
    }
})

export default store