import { Link } from "react-router-dom"


const Logo = ({ className, onClick }) => {
    return (
        <Link to="/" className={`flex justify-center items-center w-44 h-auto md:w-56 md:h-auto ${className}`} onClick={onClick}>
            {/* <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="Logo" className="w-full h-auto" /> */}
            <img src={'/static/assets/logo.png'} alt="Logo" className="w-full h-auto" />
        </Link>
    )
}

export default Logo