import { useState } from 'react'
import { getRandomItem } from '../../utils/getRandomItem'


const Masonry = ({ children }) => {
    return (
        <div className='masonry flex items-center gap-x-4 sm:gap-x-10'>
            {children}
        </div>
    )
}

export const MasonryColumn = ({ children }) => {
    return (
        <div className={`masonry__column flex flex-col gap-y-4 sm:gap-y-8`}>
            {children}
        </div>
    )
}

export const Image = ({ src, alt }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [durations, setDurations] = useState(['animate-0.6', 'animate-0.8', 'animate-1', 'animate-1.2', 'animate-1.4'])

    return (
        <img 
            onLoad={() => setIsLoaded(true)}
            alt={alt} 
            src={src} 
            className={`column__image w-72 h-auto ${isLoaded ? 'visible' : 'invisible'} ${isLoaded ? getRandomItem(durations) : ''}`} 
        />
    )
}

export default Masonry