

const OverlayNavigationContent = ({ children }) => {
    return (
        <div className={`relative top-36 w-full flex flex-col text-center content-center items-center mt-3`}>
            {children}
        </div>
    )
}

export default OverlayNavigationContent