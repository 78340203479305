import { useForm } from "react-hook-form"
import axios from "axios"
import { URLS } from "../../settings"

import ContactForm from "../../components/ContactForm/ContactForm"
import Input from "../../components/ContactForm/Input"
import Textarea from "../../components/ContactForm/Textarea"
import SubmitButton from "../../components/ContactForm/SubmitButton"
import Loader from '../../components/Loader/Loader'
import ContactList, { ContactListItem } from "./ContactList"

import { AiOutlineMail } from "react-icons/ai"
import { BsInstagram } from "react-icons/bs"
import { useState } from "react"



const Contact = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            email: "",
            subject: "",
            message: ""
        }
    })

    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const { baseUrl, sendEmailUrl } = URLS


    const onSubmit = data => {
        setIsLoading(true)
        setIsDisabled(true)

        const { email, subject, message } = data

        axios.post(
            baseUrl + sendEmailUrl,
            {
                'email': email,
                'subject': subject,
                'message': message
            },
            { headers: { "Content-Type": "application/json" } }
        )
        .then(response => {
            console.log(response.data)
            reset()
            setIsLoading(false)
            setIsDisabled(false)
        })
        .catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }

    return (
        <div className="mx-8">
            <h1 className="text-3xl mb-10">Contact</h1>

            <ContactList>
                <ContactListItem 
                    icon={<AiOutlineMail />}
                    text="nataliaerdman@gmail.com"
                    onClick={(e) => {
                        window.location.href = 'mailto:nataliaerdman@gmail.com'
                        e.preventDefault()
                    }}
                />
                <ContactListItem 
                    path={{ pathname: '//www.instagram.com/nataliaerdman/' }}
                    target="_blank"
                    icon={<BsInstagram />} 
                    text="@nataliaerdman"
                />
            </ContactList>

            <ContactForm
                onSubmit={handleSubmit(onSubmit)}
            >
                <Input 
                    label="Email"
                    register={register}
                    name="email"
                    isRequired={true}
                    errors={errors.email}
                />
                <Input
                    label="Subject"
                    register={register}
                    name="subject"
                    isRequired={true}
                    errors={errors.subject}
                />
                <Textarea
                    label="Message"
                    register={register}
                    name="message"
                    rows="5"
                    isRequired={true}
                    errors={errors.message}
                />
                <div className="flex flex-row items-center">
                    <SubmitButton 
                        value="Send message"
                        disabled={isDisabled}
                    />
                    <Loader 
                        isLoading={isLoading}
                    />
                </div>
            </ContactForm>
        </div>
    )
}

export default Contact