import { IconContext } from "react-icons/lib/esm/iconContext"
import { MdClose } from "react-icons/md"


const CloseButton = ({ onClick }) => {
    return (
        <IconContext.Provider
            value={{ }}
        >
            <MdClose 
                className="absolute w-10 h-auto top-4 right-4 hover:cursor-pointer"
                onClick={onClick}
            />
        </IconContext.Provider>
    )
}

export default CloseButton