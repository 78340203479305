import { useState } from "react";
import { Link } from "react-router-dom";
import { NAVIGATION } from "../../data/navigation"
import useNavigationIcon from "../../hooks/useNavigationIcon";

import Logo from "../Logo/Logo"
import MenuIcon from "../MenuIcon/MenuIcon";
import Item from "../Navigation/Item"
import Navigation from "../Navigation/Navigation"
import CloseButton from "../OverlayNavigation/CloseButton";
import OverlayNavigation from "../OverlayNavigation/OverlayNavigation"
import OverlayNavigationContent from "../OverlayNavigation/OverlayNavigationContent"
import OverlayNavigationItem from "../OverlayNavigation/OverlayNavigationItem"
import { ContactListItem } from "../../pages/Contact/ContactList";
import { BsInstagram } from "react-icons/bs"
import OverlayNavigationList from "../OverlayNavigation/OverlayNavigationList";
import { IconContext } from "react-icons";


const Menu = () => {
    const isNavigationIconActive = useNavigationIcon(900)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <header className="sticky top-0 left-0 z-[999] flex flex-row justify-between p-4 bg-white">
            <Logo />
            {isNavigationIconActive === false
                ?
                    <Navigation>
                        {NAVIGATION.map(item =>
                            <Item 
                                key={item.name} 
                                path={item.path} 
                                text={item.name} 
                            />
                        )}
                        <li className="inline p-2 mx-8">
                            <Link 
                                to={{ pathname: '//www.instagram.com/nataliaerdman/' }}
                                target="_blank"
                            >
                                <IconContext.Provider value={{ className: 'inline', size: '30px' }}>
                                    <BsInstagram />
                                </IconContext.Provider>
                            </Link>
                           
                        </li>
                    </Navigation>
                :
                    <MenuIcon 
                        onClick={() => setIsMenuOpen(true)}
                    />
            }
            <OverlayNavigation
                width={isMenuOpen ? 'w-full' : 'w-0'}
            >
                <CloseButton 
                    onClick={() => setIsMenuOpen(false)}
                />
                <OverlayNavigationContent>
                    <OverlayNavigationList>
                        {NAVIGATION.map((item, index) =>
                            <OverlayNavigationItem
                                key={index}
                                path={item.path}
                                text={item.name}
                                onClick={() => setIsMenuOpen(false)}
                            />
                        )}
                    </OverlayNavigationList>
                    <Logo
                        className="mt-12" 
                        onClick={() => setIsMenuOpen(false)}
                    />
                    <div className="mt-10">
                        <Link
                            to={{ pathname: '//www.instagram.com/nataliaerdman/' }}
                            target="_blank"
                        >
                            <IconContext.Provider value={{ size: '30px' }}>
                                <BsInstagram />
                            </IconContext.Provider>
                        </Link>
                    </div>
                </OverlayNavigationContent>
            </OverlayNavigation>
        </header>
    )
}

export default Menu