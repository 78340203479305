import { useState } from "react"


const SessionCard = ({ imageSrc, alt, name }) => {
    const [isLoaded, setIsLoaded] = useState(false)

    return (
        <div className="relative group sm:hover:cursor-pointer">
            {/* <p className="absolute w-4/5 p-2 text-sm text-center text-black bg-white bottom-4 left-1/2 -translate-x-1/2 sm:invisible sm:bg-transparent sm:text-base sm:text-transparent sm:transition-all sm:ease-in-out sm:duration-200 sm:group-hover:visible sm:group-hover:text-black sm:group-hover:bg-white">{name}</p> */}
            {/* <p className="absolute w-4/5 p-2 text-base text-center text-white bottom-4 left-1/2 -translate-x-1/2 sm:invisible sm:text-lg sm:text-transparent sm:transition-all sm:ease-in-out sm:duration-200 sm:group-hover:visible sm:group-hover:text-white">{name}</p> */}
            <img 
                src={imageSrc} 
                alt={alt} 
                onLoad={() => setIsLoaded(true)}
                className={`w-80 h-auto sm:transition-all sm:ease-in-out sm:duration-200 sm:hover:opacity-60 ${isLoaded ? 'visible' : 'invisible'} ${isLoaded ? 'animate-0.1' : ''}`}
            />
        </div>
    )
}

export default SessionCard