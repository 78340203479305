

const Input = ({ label, register, name, isRequired, errors }) => {
    return (
        <div className="relative flex flex-col w-3/4 sm:w-2/5">
            <label>{label}</label>
            <input 
                type="text" 
                autoComplete="off"
                spellCheck="false"
                errors={errors}
                className="tracking-wide border-2 border-gray-300 outline-none p-2 mb-8 transition-all duration-200 focus:shadow-md focus:shadow-gray-300" 
                {...register(`${name}`, { required: isRequired })} 
            />
            {errors && <span className="absolute text-sm bottom-[10px] left-0 text-red-500">Field required</span>}
        </div>
    )
}

export default Input