import { Link } from "react-router-dom"


const OverlayNavigationItem = ({ path, text, onClick }) => {
    return (
        <li 
            className="text-lg p-1 my-4 sm:transition sm:duration-150 sm:ease-in-out sm:hover:text-gray-300"
            onClick={onClick}
        >
            <Link to={path}>
                {text}
            </Link>
        </li>
    )
}

export default OverlayNavigationItem 