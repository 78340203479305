import { useEffect, useState } from "react"


const useOverlayNavigation = (breakpoint) => {
    const [isActive, setIsActive] = useState(false)

    const setOverlayNavigationState = (breakpoint) => {
        if (window.innerWidth <= breakpoint) {
            setIsActive(true)
        } else if (window.innerWidth > breakpoint) {
            setIsActive(false)
        }
    }

    useEffect(() => {
        setOverlayNavigationState(breakpoint)
    })

    useEffect(() => {
        window.addEventListener('resize', () => {
            setOverlayNavigationState(breakpoint)
        })

        return () => {
            window.removeEventListener('resize', setOverlayNavigationState(breakpoint))
        }
        
    }, [isActive, breakpoint])

    return isActive
}

export default useOverlayNavigation