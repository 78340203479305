import { 
    createAsyncThunk, 
    createSlice 
} from '@reduxjs/toolkit'
import axios from 'axios'


const initialState = {
    singlePhotoSession: {},
    status: 'idle',
    error: null
}

export const fetchSinglePhotoSession = createAsyncThunk(
    'fetchPhotoSessions/fetchSinglePhotoSession',
    async (url) => {
        const response = await axios.get(url, {
            'Access-Control-Allow-Origin': 'https://nataliaerdman.com',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Content-Type',
            'Content-Type': 'application/json'
        })

        return response.data
    }
)

const singlePhotoSessionSlice = createSlice({
    name: 'singlePhotoSession',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchSinglePhotoSession.pending, (state, action) => {
                state.photoSessions = []
                state.status = 'loading'
                state.isLoading = true
            })
            .addCase(fetchSinglePhotoSession.fulfilled, (state, action) => {
                state.isLoading = false
                state.status = 'succeeded'
                state.singlePhotoSession = action.payload
            })
            .addCase(fetchSinglePhotoSession.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default singlePhotoSessionSlice.reducer

export const selectSinglePhotoSession = state => state.singlePhotoSession.singlePhotoSession

export const selectStatus = state => state.singlePhotoSession.status

export const selectIsLoading = state => state.singlePhotoSession.isLoading

export const selectError = state => state.singlePhotoSession.error