import { IconContext } from "react-icons"
import { Link } from "react-router-dom"


const ContactList = ({ children }) => {
    return (
        <ul className="flex flex-col list-none my-16">
            {children}
        </ul>
    )
}

export const ContactListItem = ({ path, target, icon, text, onClick }) => {
    return (
        <li className="w-min my-3">
            <IconContext.Provider value={{ size: '30px' }}>
                <Link 
                    to={{...path}}
                    target={target}
                    onClick={onClick}
                    className="flex flex-row items-center"
                >
                    {icon}
                    {text && <p className="text-lg mx-2">{text}</p>}
                </Link>
            </IconContext.Provider>
        </li>
    )
}

export default ContactList