import { useState } from "react"


const SessionImage = ({ src, alt }) => {
    const [isLoaded, setIsLoaded] = useState(false)

    return (
        <img 
            src={src} 
            alt={alt} 
            onLoad={() => setIsLoaded(true)}
            className={`w-full h-auto my-1 ${isLoaded ? 'visible' : 'invisible'} ${isLoaded ? 'animate-0.1' : ''}`}
        />
    )
}

export default SessionImage