export const URLS = {
    // DEV urls
    // baseUrl: 'http://localhost:8000',
    // randomPhotosUrl: '/api/random-photos',
    // photoSessionsUrl: '/api/photo-sessions',
    // sessionUrl: '/api/sessions',
    // sendEmailUrl: '/api/send-email'

    // PROD urls
    baseUrl: '/api',
    randomPhotosUrl: '/random-photos',
    photoSessionsUrl: '/photo-sessions',
    sessionUrl: '/sessions',
    sendEmailUrl: '/send-email'
}