import { useEffect } from "react"
import { useState } from "react"
import { IconContext } from "react-icons"
import { IoIosArrowUp } from "react-icons/io"



const ScrollButton = ({ yOffset }) => {
    const [isVisible, setIsVisible] = useState(false)

    const displayScrollButton = (offset) => {
        if (!isVisible && window.pageYOffset > offset) {
            setIsVisible(true)
        } else if (isVisible && window.pageYOffset <= offset) {
            setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({ top: '0', behavior: 'smooth' })
    }

    useEffect(() => {
        window.addEventListener('scroll', () => displayScrollButton(yOffset))

        return () => {
            window.removeEventListener('scroll', () => displayScrollButton(yOffset))
        }
    })

    return (
        <IconContext.Provider
            value={{ size: '50' }}
        >
            <div 
                className={`${isVisible ? 'visible' : 'invisible'} fixed right-4 bottom-4 hover:cursor-pointer`}
                onClick={() => scrollToTop()}
            >
                <IoIosArrowUp />
            </div>
        </IconContext.Provider>
    )
}

export default ScrollButton