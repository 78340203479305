

export const groupByAmount = (arr, count) => {
    let results = []
    let counter = 0

    for (let i = 0; i <= (arr.length / count) - 1; i++) {
        results.push([])

        for (let j = 0; j <= count - 1; j++) {
            results[i].push(arr[counter])
            counter += 1
        }
    }

    return results
}