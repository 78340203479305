import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { URLS } from "../../settings"

import FlexContainer from "../../components/FlexContainer/FlexContainer"
import SessionCard from "../../components/SessionCard/SessionCard"
import { Link } from "react-router-dom"

import { 
    fetchPhotoSessions, 
    selectError, 
    selectIsLoading, 
    selectPhotoSessions 
} from "../../features/photoSessions/photoSessionsSlice"



const Category = () => {
    const { name } = useParams()
    const dispatch = useDispatch()

    const photoSessions = useSelector(selectPhotoSessions)
    const isLoading = useSelector(selectIsLoading)
    const error = useSelector(selectError)

    const { baseUrl, photoSessionsUrl } = URLS


    useEffect(() => {
        dispatch(fetchPhotoSessions(baseUrl + photoSessionsUrl + '/' + name))
    }, [dispatch, baseUrl, photoSessionsUrl, name])
    

    return (
        <FlexContainer
            className="flex flex-row flex-wrap justify-center items-center px-20"
        >
            {isLoading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {photoSessions &&
                photoSessions.map(session =>
                    <Link 
                        to={`/sessions/${session.id}`} key={session.id}
                        className="m-4"
                    >
                        <SessionCard
                            imageSrc={session.session_image}
                            name={session.display_name}
                        />
                    </Link>
                )
            }
        </FlexContainer>
    )
}

export default Category