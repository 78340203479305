

const Navigation = ({ children }) => {
    return (
        <ul className="list-none flex items-center">
            {children}
        </ul>
    )
}

export default Navigation