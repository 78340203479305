import { 
    createAsyncThunk, 
    createSlice 
} from '@reduxjs/toolkit'
import axios from 'axios'


const initialState = {
    photoSessions: [],
    isLoading: true,
    error: null
}

export const fetchPhotoSessions = createAsyncThunk(
    'fetchPhotoSessions/fetchPhotoSessions',
    async (url) => {
        const response = await axios.get(url, {
            'Access-Control-Allow-Origin': 'https://nataliaerdman.com',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Content-Type',
            'Content-Type': 'application/json'
        })

        return response.data
    }
)

const photoSessionsSlice = createSlice({
    name: 'photoSessions',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPhotoSessions.pending]: (state) => {
            state.photoSessions = []
            state.isLoading = true
        },
        [fetchPhotoSessions.fulfilled]: (state, action) => {
            state.isLoading = false
            state.photoSessions = action.payload
        },
        [fetchPhotoSessions.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        },
    }
})

export default photoSessionsSlice.reducer

export const selectPhotoSessions = state => state.photoSessions.photoSessions

export const selectIsLoading = state => state.photoSessions.isLoading

export const selectError = state => state.photoSessions.error