

const SubmitButton = ({ value, disabled }) => {
    return (
        <input 
            type="submit" 
            value={value} 
            className="w-fit px-6 py-2 border-2 transition-shadow duration-200 border-gray-300 hover:cursor-pointer hover:shadow-md hover:shadow-gray-300" 
            disabled={disabled}
        />
    )
}

export default SubmitButton