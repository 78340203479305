import { Link } from "react-router-dom"
import FlexContainer from "../../components/FlexContainer/FlexContainer"


const About = () => {
    return (
        <div className="mx-6">
            <h1 className="text-3xl mb-10">About</h1>
            <FlexContainer
                className="flex flex-col items-center mt-20 sm:flex-row sm:justify-center"
            >
                <div>
                    {/* <img className="w-96 h-auto" src={process.env.PUBLIC_URL + '/assets/about/about.jpg'} alt="" loading="lazy" /> */}
                    <img className="w-96 h-auto" src={'static/assets/about.jpg'} alt="" loading="lazy" />
                </div>
                <FlexContainer
                    className="flex mt-10 flex-col justify-center sm:mt-0"
                >
                    <p className="mx-10 my-6">Polish photographer.</p>
                    <p className="mx-10 my-6">Born in 1988.</p>
                    <p className="mx-10 my-6">Publications: Vogue Poland, Elle Poland.</p>
                    <p className="mx-10 my-6">
                        Instagram:
                        <Link
                            className="w-min"
                            to={{ pathname: '//www.instagram.com/nataliaerdman/' }}
                            target="_blank"
                        >
                            &nbsp;@nataliaerdman
                        </Link>
                    </p>
                </FlexContainer>
            </FlexContainer>
        </div>
    )
}

export default About