

const OverlayNavigationList = ({ children }) => {
    return (
        <ul className="list-none">
            {children}
        </ul>
    )
}

export default OverlayNavigationList