

const SessionHeading = ({ text }) => {
    return (
        <h1 className="text-2xl mx-8 md:text-4xl md:mx-4 font-bold text-center  before:content-['“'] before:mr-2 after:content-['”'] after:ml-2">
            {text}
        </h1>
    )
}

export default SessionHeading