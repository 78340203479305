import { Link } from "react-router-dom"


const Item = ({ path, text }) => {
    return (
        <li 
            className="inline text-base p-2 mx-8 transition duration-150 ease-in-out hover:text-gray-300"
        >
            <Link to={path}>
                {text}
            </Link>
        </li>
    )
}

export default Item