

export const NAVIGATION = [
    {
        name: 'Work',
        path: '/category/work'
    },
    {
        name: 'Travels',
        path: '/category/travels'
    },
    {
        name: 'Beauty',
        path: '/category/beauty'
    },
    {
        name: 'About',
        path: '/about'
    },
    {
        name: 'Contact',
        path: '/contact'
    }
]