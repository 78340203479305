import Masonry, { MasonryColumn, Image } from "../../components/Masonry/Masonry"
import { 
    useSelector, 
    useDispatch 
} from "react-redux"
import { 
    fetchRandomPhotos, 
    selectError, 
    selectRandomPhotos, 
    selectStatus 
} from "../../features/photos/photosSlice"
import { useEffect, useState } from "react"
import { groupByAmount } from "../../utils/groupByAmount"
import useTrackWindowWidth from "../../hooks/useTrackWindowWidth"
import { URLS } from "../../settings"



const Home = () => {
    const dispatch = useDispatch()
    const randomPhotos = useSelector(selectRandomPhotos)
    const status = useSelector(selectStatus)
    const error = useSelector(selectError)
    let windowWidth = useTrackWindowWidth()
    const [groupedPhotos, setGroupedPhotos] = useState([])

    const { baseUrl, randomPhotosUrl } = URLS


    useEffect(() => {
        dispatch(fetchRandomPhotos(baseUrl + randomPhotosUrl))
    }, [dispatch, baseUrl, randomPhotosUrl])

    useEffect(() => {
        setGroupedPhotos([])
    }, [])

    useEffect(() => {
        if (status === 'succeeded') setGroupedPhotos(groupByAmount(randomPhotos, 2))
    }, [status, randomPhotos])

    // Todo!!!
    useEffect(() => {
        if (windowWidth > 900) {
            setGroupedPhotos(groupByAmount(randomPhotos, 2))
        } else if (windowWidth <= 900) {
            setGroupedPhotos(groupByAmount(randomPhotos, 3))
        }
    }, [windowWidth, randomPhotos])

    return (
        <div className="flex justify-center">
                <Masonry>
                    {status === 'loading' && <div>Loading...</div>}
                    {status === 'failed' && <div>{error}</div>}
                    {status === 'succeeded' &&
                        groupedPhotos.map((item, index) =>
                            <MasonryColumn
                                key={index}
                            >
                                {item.map((photo, index) =>
                                    <Image
                                        key={index}
                                        alt={photo.alt}
                                        src={`${photo.image}`}
                                        // src={`https://nataliaerdman.com${photo.image}`}
                                    />
                                )}
                            </MasonryColumn>
                        )}
                </Masonry>
        </div>
    )
}

export default Home