

const OverlayNavigation = ({ children, width }) => {
    return (
        <div className={`fixed z-10 left-0 top-0 h-full ${width} bg-white overflow-x-hidden transition-all ease-in-out duration-200`}>
            {children}
        </div>
    )
}

export default OverlayNavigation