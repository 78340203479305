import { IconContext } from "react-icons/lib/esm/iconContext"
import { GiHamburgerMenu } from "react-icons/gi"


const MenuIcon = ({ onClick }) => {
    return (
        <IconContext.Provider
            value={{ className: 'w-10 h-auto hover:cursor-pointer' }}
        >
            <GiHamburgerMenu 
                onClick={onClick}
            />
        </IconContext.Provider>
    )
}

export default MenuIcon