

const Textarea = ({ label, register, name, rows, isRequired, errors }) => {
    return (
        <div className="relative flex flex-col w-full sm:w-3/4">
            <label>{label}</label>
            <textarea
                spellCheck="false"
                errors={errors}
                className="border-2 border-gray-300 outline-none p-2 mb-10 transition-shadow duration-200 focus:shadow-md focus:shadow-gray-300" 
                {...register(`${name}`, { required: isRequired } )} rows={rows} 
            />
            {errors && <span className="absolute text-sm bottom-[18px] left-0 text-red-500">Field required</span>}
        </div>
    )
}

export default Textarea