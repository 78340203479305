import { 
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import Home from './pages/Home/Home'
import About from './pages/About/About'
import Contact from './pages/Contact/Contact'
import Category from './pages/Category/Category'
import Session from './pages/Session/Session'
import Menu from './components/Menu/Menu'
import ScrollButton from './components/ScrollButton/ScrollButton'



function App() {
  return (
    <div className="App">
      <Router>
        <Menu />
        <div className="container mx-auto my-8">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/category/:name' element={<Category />} />
            <Route path='sessions/:id' element={<Session />} />
          </Routes>
        </div>
      </Router>
      <ScrollButton
        yOffset={300}
      />
    </div>
  );
}

export default App;
