import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    useDispatch,
    useSelector
} from 'react-redux'
import { URLS } from '../../settings'

import FlexContainer from '../../components/FlexContainer/FlexContainer'
import SessionImage from '../../components/SessionImage/SessionImage'
import { fetchSinglePhotoSession, selectError, selectSinglePhotoSession, selectStatus } from '../../features/photoSessions/singlePhotoSessionSlice'
import SessionHeading from './SessionHeading'



const Session = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const photoSession = useSelector(selectSinglePhotoSession)
    const status = useSelector(selectStatus)
    const error = useSelector(selectError)

    const { baseUrl, sessionUrl } = URLS


    useEffect(() => {
        dispatch(fetchSinglePhotoSession(baseUrl + sessionUrl + '/' + id))
    }, [dispatch, baseUrl, sessionUrl, id])

    return (
        <>
            {status === 'loading' && <div>Loading...</div>}
            {status === 'failed' && <div>{error}</div>}
            {status === 'succeeded' &&
                <FlexContainer
                    className="flex flex-col items-center sm:flex-row sm:justify-around sm:items-start"
                >
                    <div className="mb-12 sm:sticky sm:top-32 sm:mx-6">
                        <SessionHeading
                            text={photoSession.display_name}
                        />
                    </div>
                    <div className="flex flex-col mx-6">
                        {photoSession.images.map(image =>
                            <div 
                                key={image.id}
                                className="max-w-lg"
                            >
                                <SessionImage
                                    src={image.image}
                                    alt={image.alt}
                                />
                            </div>
                        )}
                    </div>
                </FlexContainer>
            }
        </>
    )
}

export default Session