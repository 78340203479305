import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"


const initialState = {
    randomPhotos: [],
    status: 'idle',
    error: null
}

export const fetchRandomPhotos = createAsyncThunk(
    'fetchRandomPhotos',
    async (url) => {
        const response = await axios.get(url, {
            'Access-Control-Allow-Origin': 'https://nataliaerdman.com',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Content-Type',
            'Content-Type': 'application/json'
        })

        return response.data
    }
)

const photosSlice = createSlice({
    name: 'photos',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchRandomPhotos.pending, (state, action) => {
                state.randomPhotos = []
                state.status = 'loading'
            })
            .addCase(fetchRandomPhotos.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.randomPhotos = action.payload
            })
            .addCase(fetchRandomPhotos.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export default photosSlice.reducer

export const selectRandomPhotos = state => state.photos.randomPhotos

export const selectStatus = state => state.photos.status

export const selectError = state => state.photos.error