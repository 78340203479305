import { useEffect, useState } from "react"


const useTrackWindowWidth = () => {
    const [width, setWidth] = useState(0)

    const trackWindowWidth = () => {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', trackWindowWidth)

        return () => {
            window.removeEventListener('resize', trackWindowWidth)
        }
    }, [width])

    return width
}

export default useTrackWindowWidth